.content_products {
  display: grid;
  grid-template-columns: repeat(3, 32.3%);
  gap: 1rem;
  margin: 0 auto;
}

.card_product {
  background-color: white;
  padding: 20px;
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.card_product:hover {
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08);
}

.toggle_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  background-color: white;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08);
  padding: 10px 16px;
  border-radius: 100px;
  margin: 0 5px;
}

.product_info {
  padding-right: 1rem;
}

.product_info h3 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  color: gray;
}

.product_info p {
  font-weight: 200;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: gray;
  text-align: left;
}

.prices {
  display: flex;
  align-items: center;
}

.prices h2 {
  margin: 10px 0;
  font-size: 16px;
  color: black;
}

.prices h4 {
  margin: auto 0;
  font-size: 16px;
  color: gray;
  margin-left: 10px;
  text-decoration: line-through;
}

.tags_product {
  margin-top: 10px;
}

.img-detailts {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center center;
}

.product_img {
  margin-right: 10px;
}

.product_img img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center center;
}

.line-container {
  width: 220px;
}
.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.alergenos_cont {
  display: flex;
  margin: 10px 0px;
}

.alergenos_cont img{
  width: 25px;
  height: 25px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100px;
  margin: 0 3px;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .content_products {
    grid-template-columns: repeat(1, 99%);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .content_products {
    grid-template-columns: repeat(1, 99%);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content_products {
    grid-template-columns: repeat(1, 99%);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .content_products {
    grid-template-columns: repeat(2, 49%);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .content_products {
    grid-template-columns: repeat(2, 49%);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .content_products {
    grid-template-columns: repeat(3, 32.3%);
  }
}
