.brand {
  width: 100%;
  max-width: 1000px;
  margin: 10rem auto;
}

.brand h1 {
  text-align: center;
  font-weight: 900;
}

.brand p {
  color: gray !important;
  text-align: center;
  margin-bottom: 3rem;
}

.brand_cont {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.brand::-webkit-scrollbar {
  display: none;
}

.brand img {
  width: 120px;
  border-radius: 100px;
  object-fit: cover;
  object-position: center center;
  margin: 20px 30px;
}
