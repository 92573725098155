.Add_menu {
  cursor: pointer;
  border: 2px dashed var(--primary-color);
  border-radius: 10px;
  margin-top: 20px;
  padding-top: 15px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
}

.contents_modal {
  margin: 20px 20px;
}

.imagen_prod_add_category_upd {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  object-fit: cover;
  object-position: center center;
}
