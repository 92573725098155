.nav_shadow {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(9px);
  position: fixed;
  z-index: 100;
  transition: .5s ease;
  justify-content: center;
  display: flex;
  align-items: center;

}

.nav {
  position: relative;
  width: 100%;
  padding: 10px 10px !important;
  background-color: transparent;
  position: fixed;
  z-index: 99;
  margin-top: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.cont {
  width: 100%;
  max-width: 1250px;
  display: flex;
  align-items: center;
}

.cont a img {
  width: 140px;
  object-fit: cover;
  object-position: center center;
}

.cont_rigth {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.cont_rigth ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.cont_rigth li {
  float: left;
}

.cont_rigth a {
  display: block;
  color: var(--secondary-color);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.cont_rigth a:hover {
  color: var(--secondary-color);
}

.btn_Login {
  padding: 10px 26px !important;
  border: none;
  outline: none;
  cursor: pointer;
  color: white !important;
  border-radius: 100px;
  margin-top: 5px;
  margin-left: 10px;
  font-weight: 600;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);


}

.logosmall {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .links {
    display: none;
  }

  .logosmall {
    display: flex;
    width: 40px !important;
    height: 40px !important;
    ;
    object-fit: contain;
    object-position: center center;
  }

  .logo {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .links {
    display: none;
  }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .links {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .links {
    display: inline;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .links {
    display: inline;
  }
}
