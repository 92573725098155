.header_cont {
  width: 100%;
  height: 80px;
  background-color: #eee;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.header_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.notification_btn {
  background-color: var(--primary-color);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
}
