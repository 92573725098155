.list-group-item {
  cursor: move;
  list-style: none;
  background-color: #f9f9f9;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-group-item h1 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.list-group-item p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  color: gray;
}

.row div h1 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.imagen_prod_add_category {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  object-fit: cover;
  object-position: center center;
}

.imagen_prod_add_category_upd {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  object-fit: cover;
  object-position: center center;
}

.rigth_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
