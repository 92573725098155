:root {
  --primary-color: #8F37FF; /* Primary color, use with var(--primary-color) */
  --secondary-color: #2B0062; /* Secondary color, use with var(--secondary-color) */
  --tertiary-color: #ffffff; /* Tertiary color, use with var(--tertiary-color) */
  --quaternary-color: #455760; /* Quaternary color, use with var(--quaternary-color) */
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
