.card_content {
  background-color: white;
  height: auto;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
}

.card_content img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  object-fit: cover;
  object-position: center center;
}

.info_cont {
  margin-left: 15px;
  border-right: 1px solid gainsboro;
  padding-right: 10px;
  width: 300px;
}

.info_cont h1 {
  font-weight: bold;
  font-size: 22px;
}

.info_cont p {
  font-weight: 200;
  padding-right: 15px;
  color: black;
}

.details {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 5px 20px;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
}

.info_cont button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 5px 50px;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
}

.modal_contents {
  padding: 20px;
}
