.rating_cont {
  padding: 20px;
}

.rating_cont_header {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating_cont_header h1,
.rating_cont_items h1 {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}

.rating_cont_items h1 {
  text-align: center;
}

.rating_cont_items {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  margin-top: 2rem;
}

.content {
  width: 100%;
  max-width: 780px;
  margin: 5rem auto;
}

.item_coment {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08);
  margin: 1rem 0;
}
