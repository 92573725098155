.container_barner {
  padding: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
}

.container_barner_bg {
  padding: 0;
  background-color: white;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url("../../../Assets/images/Restaurant-owner.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 80vh;
}

.container_barner_item {
  display: grid;
  grid-template-columns: 60% 40%;
  padding-top: 120px;
}

.titles {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold !important;
  font-size: 42px;
  color: white !important;
  line-height: 1.2rem;
}
.subtitles {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300 !important;
  color: white !important;
  margin-bottom: 30px;
}

.carrousel {
  width: 100%;
  height: 450px;
  margin-top: 80px;
}

.carrousel img {
  width: 100%;
  height: 450px;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
  object-fit: cover;
  object-position: center center;
}

.forms {
  margin-bottom: 5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 480px) {
  .container_barner {
    height: 120vh;
  }
  .container_barner_item {
    grid-template-columns: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .fotos {
    display: none;
  }

  .titles {
    margin-left: 0px;
    margin-right: 0px;
    color: white !important;
    line-height: 2.5rem;
  }

  .subtitles {
    margin-left: 0px;
    margin-right: 0px;
    color: white !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 480px) {
  .container_barner {
    height: 110vh;
  }

  .container_barner_item {
    grid-template-columns: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .fotos {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container_barner {
    height: 100vh;
  }

  .container_barner_item {
    grid-template-columns: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .fotos {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container_barner_item {
    grid-template-columns: 50% 50%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fotos {
    display: inline;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container_barner_item {
    grid-template-columns: 60% 40%;
  }

  .fotos {
    display: inline;
  }
}

