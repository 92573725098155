.sesion2 {
  width: 100%;
  margin-top: 10rem;
}

.ti {
  text-align: center;
  margin-bottom: 5rem;
  font-weight: 700;
}

.sesion2_items {
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
}

.sesion2_items div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.apps {
  display: flex;
  flex-direction: row;
}

.apps a {
  margin: 10px;
}
.apps a img {
  width: 150px;
  object-fit: cover;
  object-position: center center;
}

.sesion2_items div h1 {
  font-weight: 900;
  text-align: center;
}

.sesion2_items div p {
  text-align: center;
  color: gray !important;
  padding: 30px;
}

.movil {
  width: 450px;
  object-fit: cover;
  object-position: center center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sesion2_items {
    grid-template-columns: 100%;
  }

  .movil {
    width: 250px;
    object-fit: cover;
    object-position: center center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sesion2_items {
    grid-template-columns: 100%;
  }

  .movil {
    width: 300px;
    object-fit: cover;
    object-position: center center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sesion2_items {
    grid-template-columns: 100%;
  }

  .movil {
    width: 350px;
    object-fit: cover;
    object-position: center center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sesion2_items {
    grid-template-columns: repeat(2, 50%);
  }

  .movil {
    width: 450px;
    object-fit: cover;
    object-position: center center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sesion2_items {
    grid-template-columns: repeat(2, 50%);
  }

  .movil {
    width: 450px;
    object-fit: cover;
    object-position: center center;
  }
}
