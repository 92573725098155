.new-menu-cart-container {
  height: auto;
  top: 160px;
  right: 0;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  background-color: #fbfbfb;
}

.new-cart-wrapper {
  background-color: #fbfbfb;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.new-cart-content {
  padding: 15px 10px 25px;
  border: 1px solid #c0ccda;
  border-radius: 5px;
  background-color: #fbfbfb;
  min-width: 100px;
  width: 100%;
  display: flex;
}

.new-cart-wrapper:after,
.new-cart-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
  background: radial-gradient(
    closest-side,
    #fbfbfb 32%,
    #fbfbfb 50%,
    #c0ccda 53%,
    #c0ccda 56%,
    transparent 0
  );
  background-size: 50px 50px;
  background-position: 25 -25px;
  background-repeat: repeat-x;
}

.new-cart-wrapper:before {
  top: -25px;
  bottom: 100px;
}

.new-cart-wrapper:after {
  bottom: -25px;
}
.carts {
  min-height: 300px;
}

.items-cart {
  border: 1px solid #c0ccda;
  background-color: white;
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  margin-top: 20px;
}

.items-cart h3 {
  font-weight: 700;
}

.product_item {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  min-width: 500px;
}

.product_item img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.product_content {
  width: 100%;
  border-bottom: 1px solid #c0ccda;
  margin-left: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.product_content h1 {
  font-weight: 700;
  font-size: 20px;
}

.product_content p {
  font-weight: 300;
  font-size: 14px;
  color: gray;
  margin: 0px !important;
  padding: 0px !important;
}

.product_quantity {
  width: 50px;
  border-right: 1px solid #c0ccda;
  color: var(--primary-color);
}

.product_quantity h3 {
  font-size: 16px;
  color: var(--primary-color);
}

.items-cart-resumen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-cart-resumen ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}

.items-cart-resumen ul,
li,
p {
  font-size: 14px;
  font-weight: 400;
}

.items-cart-resumen h1 {
  font-weight: 700;
}

.items-cart-paymentMethod {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.img_payment {
  width: 100px;
  height: 70px;
  object-fit: contain;
  object-position: center center;
}

.items-cart-paymentMethod p {
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.JSON_VIEW {
  margin-top: 4rem;
}
