.add_cont {
  border: 2px dashed var(--primary-color);
  border-radius: 10px;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.add_cont p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: var(--primary-color);
  font-weight: 300;
}

.imagen_prod_add {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center center;
}

.ant-upload-text p {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 300;
}

.content_modal {
  margin: 30px 20px;
}

.content_modal {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.add_info {
  margin-top: 2rem;
}

.input_price {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.add_info p {
  margin: 0;
  font-size: 14px;
  font-weight: 200;
  color: gray;
}

.input_price div {
  width: 100%;
}

.input_price div p {
  margin: 0;
  font-size: 14px;
  font-weight: 200;
  color: gray;
}

.cat_content {
  margin-top: 2rem;
}

.headder {
  display: flex;
  justify-content: space-between;
}

#input-price {
  outline: none;
  border: 0.5px solid gainsboro;
  font-size: 14px;
  font-weight: 300;
  color: gray;
  width: 96%;
  height: 35px;
  padding: 0 15px;
}

#input-price-line {
  outline: none;
  border: 0.5px solid gainsboro;
  font-size: 14px;
  font-weight: 300;
  color: gray;
  width: 100%;
  height: 35px;
  padding: 0 15px;
  text-decoration: line-through;
}

.btn_contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 10px;
}

.btn_item {
  height: 40px;
  background-color: gainsboro;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.agotado {
  width: 150px !important;
  margin-top: 30px;
}

.agotado p {
  padding-top: 13px;
}

.new {
  background-color: #ffadd2;
}

.new p {
  color: #c41d7f !important;
}

.New {
  background-color: #c41d7f !important;
  outline: none !important;
}

.NoNew {
  background-color: #db83b7 !important;
  outline: none !important;
}

.offert {
  background-color: #adc6ff;
}

.offert p {
  color: #1d39c4 !important;
}

.Off {
  background-color: #1d39c4 !important;
  outline: none !important;
}

.NoOff {
  background-color: #8c9ce9 !important;
  outline: none !important;
}

.popu {
  background-color: #eaff8f;
}

.popu p {
  color: #7cb305 !important;
}

.btn_item p {
  font-size: 15px;
  font-weight: 300;
  color: gray;
}

.option_cont {
  margin-top: 3rem;
}

.complement__title {
  background-color: #f4f5f5;
  padding: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.complement__title p {
  color: gray;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.complement__title h3 {
  margin-left: 15px;
  padding-top: 10px;
  line-height: 5px;
}

.Requerido {
  margin-left: 15px;
  border-radius: 100px;
  color: #f5365c;
  font-size: 12px;
  font-weight: 300;
}

.no_stock {
  background-color: #f5365c;
  padding: 3px 15px;
  border-radius: 100px;
  color: white;
  font-size: 12px;
  font-weight: 200;
}
.list_chill {
  padding: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #f4f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sum_cantidad {
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 5px;
  color: black;
  font-weight: 700;
  margin-right: 15px;
}

.bundled_item_children {
  background-color: #ebebeb;
  padding: 10px;
  cursor: pointer;
}
.bundled_item_children h3 {
  margin: 0;
  padding: 0;
}

.bundled_item_item {
  padding: 10px;
}
