.horario_form h3 {
  font-size: 18px;
  margin: 0px;
}

.day {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
}

.horario_form {
  width: 90%;
}
