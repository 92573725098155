.cont_store {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.Forma_cont {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3rem;
}

.Forma {
  padding: 15px;
}

.horario {
  padding: 15px;
  margin-top: 30px;
}

.boolean_content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 2rem;
}

.Forma_prices {
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  gap: 2rem;
}

.imagen_prod_add {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}

.imagen_prod_add_logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}
